(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("classnames"), require("react"), require("react-state-effects"));
	else if(typeof define === 'function' && define.amd)
		define(["classnames", "react", "react-state-effects"], factory);
	else if(typeof exports === 'object')
		exports["reactStepper"] = factory(require("classnames"), require("react"), require("react-state-effects"));
	else
		root["reactStepper"] = factory(root["classnames"], root["react"], root["react-state-effects"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_classnames__, __WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_react_state_effects__) {
return 